import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["fileInput",
        "defaultText",
        "uploadingText",
        "processingText",
        "downloadingText",
        "completeText",
        "errorText",
        "errorDetails"
    ]
    static values = { uploadState: String }

    isDragging

    connect() {
        this.isDragging = false
        this.uploadStateValue = "default"
    }

    uploadFile() {
        console.log("uploading file")
        const formData = new FormData()
        let firstValue = this.fileInputTarget.files[0];
        formData.append("form_processor[tax_form_image]", firstValue, firstValue.name)

        var filename = "";

        this.uploadStateValue = "uploading"
        setTimeout(() => {
            if (this.uploadStateValue === "uploading") {
            this.uploadStateValue = "processing"
            }
        }, 2000);

        fetch("form_processor/upload", {
            method: "POST",
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
        .then(response => {
            console.log("request compelted; checkign response;");

            if (!response.ok) {
                this.uploadStateValue = "error"

                console.log("response not ok")

                // const reader = response.body.getReader();
                // const decoder = new TextDecoder();
                // let result = '';
                // reader.read().then(function process({done, value}) {
                //     if (done) {
                //         console.log("Stream complete");
                //         return result;
                //     }
                //
                //     result += decoder.decode(value, {stream: true});
                //
                //     return reader.read().then(process)
                // }).then( finalResult => {
                //     console.log("result: " + finalResult);
                //     this.errorDetailsTarget.innerHTML = finalResult;
                // });

                response.text().then(text => {
                    console.log(text)
                    this.errorDetailsTarget.innerHTML = text;
                })

                console.log(response)

                throw new Error(`HTTP error! status: ${response.status}`);
            }
            this.uploadStateValue = "downloading"

            setTimeout(() => {
                this.uploadStateValue = "complete"
            }, 4000)

            console.log("response ok")
            console.log(response)

            const header = response.headers.get('Content-Disposition');
            const parts = header.split(';');
            filename = parts[1].split('=')[1].replaceAll('"', '');

            // return response.text()//.json(); // This returns a promise
            return response.blob()
        })
        .then(data => {
            if (data != null) {
                var url = window.URL.createObjectURL(data);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element
            }

            console.log(data); // Log the data for now, you can handle it as per your requirement
        })
        .catch(error => {
            this.uploadStateValue = "error"
            console.log('There was a problem with the fetch operation: ' + error.message);
            console.log('here I am')
        });
    }

    drop(event) {
        console.log("dropped")
        event.preventDefault()
        this.isDragging = false
        this.changeBorder()
        this.fileInputTarget.files = event.dataTransfer.files
        this.uploadFile()
    }

    dragEnter(event) {
        if (event.target === this.element) {
            console.log("drag enter")
            event.preventDefault()
            this.isDragging = true
            this.changeBorder()
        }
    }

    dragExit(event) {
        // // is this a real leave? or just a child element?
        // this.rect = this.element.getBoundingClientRect()
        // let outsideBoundingRectangle = event.clientX < this.rect.left || event.clientX >= this.rect.right || event.clientY < this.rect.top || event.clientY >= this.rect.bottom;
        //
        // const distanceFromCenterX = Math.abs(event.clientX - this.rect.left + this.rect.right / 2 )
        // const distanceFromCenterY = Math.abs(event.clientY - this.rect.top + this.rect.bottom / 2 )
        // const maxDistanceX = this.rect.width / 2
        // const maxDistanceY = this.rect.height / 2
        // const borderRadius = parseFloat(window.getComputedStyle(this.element).borderRadius)
        //
        // // look into this... when I add this rounded corners in, the bounding rect trick stops working
        // // and the child elements trigger the drag exit event.
        // let outsideRoundedCorners = false;//distanceFromCenterX > maxDistanceX - borderRadius || distanceFromCenterY > maxDistanceY - borderRadius
        console.log(event.target)
        console.log(event.relatedTarget)
        console.log(event)
        if (event.target === this.element) {
            console.log("drag exit")
            event.preventDefault()
            this.isDragging = false
            this.changeBorder()
        }
        // if (outsideBoundingRectangle || outsideRoundedCorners) {
        //     if (outsideRoundedCorners) { console.log("outside rounded corners") }
        //     if (outsideBoundingRectangle) { console.log("outside bounding rectangle") }
        //     console.log("drag exit")
        //     event.preventDefault()
        //     this.isDragging = false
        //     this.changeBorder()
        // }
    }

    dragOver(event) {
        if (event.target === this.element) {
            console.log("drag over")
            event.preventDefault()
        }
    }

    changeBorder() {
        const dropzone = this.element;
        const defaultClasses = ["border-black", "bg-white"]
        const dragEffectClasses = ["border-primary", "bg-light-blue"]

         if (this.isDragging) {
            dropzone.classList.remove(...defaultClasses)
            dropzone.classList.add(...dragEffectClasses)
        } else {
            dropzone.classList.add(...defaultClasses)
            dropzone.classList.remove(...dragEffectClasses)
        }
    }

    uploadStateValueChanged() {
        this.clearAllUploadTextTargets()
        switch (this.uploadStateValue) {
            case "default":
                this.defaultTextTarget.classList.remove("d-none")
                break
            case "uploading":
                this.uploadingTextTarget.classList.remove("d-none")
                break
            case "processing":
                this.processingTextTarget.classList.remove("d-none")
                break
            case "downloading":
                this.downloadingTextTarget.classList.remove("d-none")
                break
            case "complete":
                this.completeTextTarget.classList.remove("d-none")
                break
            case "error":
                this.errorTextTarget.classList.remove("d-none")
                break
            default:
        }
    }

    clearAllUploadTextTargets() {
        this.defaultTextTarget.classList.add("d-none")
        this.uploadingTextTarget.classList.add("d-none")
        this.processingTextTarget.classList.add("d-none")
        this.downloadingTextTarget.classList.add("d-none")
        this.completeTextTarget.classList.add("d-none")
        this.errorTextTarget.classList.add("d-none")
    }
}